import { Api } from '@/libraries/api'
// import { Api, makeGetUrl } from '@/libraries/api'

async function getImportThingItems() {
  const url = `/extra/career`
  const result = await Api().get(url, { cache: true })
  const { items } = result.data.data
  return items
}

async function requestFindCaregiver(item) {
  const url = '/patient'
  const data = item
  const res = await Api().post(url, data)
  const { result } = res.data
  return result
}

export default {
  getImportThingItems,
  requestFindCaregiver
}
