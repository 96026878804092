<template>
  <div class="section-wrap py-0 py-sm-8">
    <v-form
      ref="form"
      v-model="valid"
      :lazy-validation="true"
    >
      <v-container class="pa-0 pa-sm-4">
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            md="8"
          >
            <v-container class="py-8 px-6 container-wrap">
              <v-row>
                <v-col
                  cols="12"
                  class="subtitle font-weight-bold text-h5 pb-8"
                >
                  간병인 신청자 정보
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <LabelTitle
                    title="신청자 연락처 정보가 필요해요"
                    class="mb-1"
                  />
                  <p
                    class="text-body-2"
                    style="color: #2C2C2C; font-size: 0.8rem !important; letter-spacing: -0.03em !important;"
                  >
                    지원한 간병인의 프로필이 카카오톡으로 전송됩니다. 원활한 서비스 제공을 위해 꼭 정확한 핸드폰 번호를 입력해주세요.
                  </p>
                  <v-text-field
                    ref="item__phone"
                    v-model.trim="item.phone"
                    :rules="[rules.required]"
                    label=""
                    placeholder="'-' 없이 숫자만 입력해주세요"
                    type="number"
                    hide-details
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            md="8"
          >
            <v-container class="py-8 px-6 container-wrap">
              <v-row>
                <v-col
                  cols="12"
                  class="subtitle font-weight-bold text-h5 pb-8"
                >
                  장소 정보
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-container class="pa-0">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <LabelTitle title="계시는 지역을 알려주세요" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          ref="item__location_idx"
                          v-model="selectedRegion"
                          :items="regions"
                          label="시/도"
                          item-text="short_name"
                          :item-value="(item) => item"
                          append-icon="expand_more"
                          outlined
                          @input="onChangeRegion"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          ref="item__location_detail_idx"
                          v-model="selectedCity"
                          :items="cities"
                          label="시/군/구"
                          item-text="short_name"
                          :item-value="(item) => item"
                          append-icon="expand_more"
                          outlined
                          @input="onChangeCity"
                        />
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <LabelTitle title="계시는 장소를 알려주세요" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        cols="6"
                      >
                        <v-btn
                          ref="item__location_type"
                          block
                          outlined
                          height="50"
                          :style="`border-color: ${selectedPlace === 'home' ? '#007AFF': '#9E9E9E'} !important;`"
                          :color="selectedPlace === 'home' ? '#007AFF' : '#2C2C2C'"
                          @click="selectPlace('home')"
                        >
                          <div
                            class="btn-icon"
                            :style="`color: ${(selectedPlace === 'home') ? '#007AFF' : '#2C2C2C'}`"
                            :class="selectedPlace === 'home' ? 'icon-home-active': 'icon-home'"
                          >
                            자택
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-btn
                          block
                          outlined
                          height="50"
                          :style="`border-color: ${selectedPlace === 'hospital' ? '#007AFF': '#9E9E9E'} !important;`"
                          :color="selectedPlace === 'hospital' ? '#007AFF' : '#2C2C2C'"
                          @click="selectPlace('hospital')"
                        >
                          <div
                            class="btn-icon"
                            :style="`color: ${(selectedPlace === 'hospital') ? '#007AFF' : '#2C2C2C'}`"
                            :class="selectedPlace === 'hospital' ? 'icon-hospital-active': 'icon-hospital'"
                          >
                            병원
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col
                        v-show="selectedPlace === 'hospital'"
                        cols="12"
                      >
                        <!-- sm="4"
                        md="4" -->
                        <v-text-field
                          ref="item__location_type_detail_1"
                          v-model.trim="item.location_type_detail_1"
                          :rules="[rules.required]"
                          label=""
                          placeholder="병원을 입력해주세요"
                          outlined
                        />
                      </v-col>
                      <v-col
                        v-show="selectedPlace === 'home' || selectedPlace === 'hospital'"
                        cols="12"
                      >
                        <!-- :sm="selectedPlace === 'hospital' ? 8 : 12"
                        :md="selectedPlace === 'hospital' ? 8 : 12" -->
                        <v-text-field
                          ref="item__location_type_detail_2"
                          v-model.trim="item.location_type_detail_2"
                          class="item__location_type_detail_2"
                          :rules="selectedPlace === 'home' ? [rules.required] : []"
                          label=""
                          :placeholder="placeDetailLabel"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            md="8"
          >
            <v-container class="py-8 px-6 container-wrap">
              <v-row>
                <v-col
                  cols="12"
                  class="subtitle font-weight-bold text-h5 pb-8"
                >
                  환자 정보
                </v-col>
                <v-col
                  cols="12"
                >
                  <LabelTitle title="환자 성함을 알려주세요" />
                  <v-text-field
                    ref="item__name"
                    v-model.trim="item.name"
                    :rules="[rules.required]"
                    label=""
                    placeholder="성함을 입력해주세요."
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-container class="pa-0">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <LabelTitle title="나이" />
                      </v-col>
                    </v-row>
                    <v-row
                      dense
                      algin="center"
                    >
                      <v-col>
                        <v-text-field
                          ref="item__age"
                          v-model.trim="item.age"
                          :rules="[rules.required, rules.age]"
                          label=""
                          placeholder="나이를 입력해주세요"
                          type="number"
                          outlined
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        class="pl-4"
                      >
                        <p class="text-subtitle-1 font-weight-medium pt-4 pb-0">
                          세
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <LabelTitle title="성별" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        cols="6"
                      >
                        <v-btn
                          ref="item__gender_male"
                          block
                          outlined
                          height="50"
                          :style="`border-color: ${item.gender === 'male' ? '#007AFF': '#9E9E9E'} !important;`"
                          :color="item.gender === 'male' ? '#007AFF' : '#2C2C2C'"
                          @click="item.gender = 'male'"
                        >
                          <div
                            class="btn-icon"
                            :style="`color: ${(item.gender === 'male') ? '#007AFF' : '#2C2C2C'}`"
                            :class="item.gender === 'male' ? 'icon-male-active': 'icon-male'"
                          >
                            남성
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-btn
                          ref="item__gender_female"
                          block
                          outlined
                          height="50"
                          :style="`border-color: ${item.gender === 'female' ? '#007AFF': '#9E9E9E'} !important;`"
                          :color="item.gender === 'female' ? '#007AFF' : '#2C2C2C'"
                          @click="item.gender = 'female'"
                        >
                          <div
                            class="btn-icon"
                            :style="`color: ${(item.gender === 'female') ? '#007AFF' : '#2C2C2C'}`"
                            :class="item.gender === 'female' ? 'icon-female-active': 'icon-female'"
                          >
                            여성
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <LabelTitle title="체중" />
                      </v-col>
                    </v-row>
                    <v-row
                      dense
                      algin="center"
                    >
                      <v-col>
                        <v-text-field
                          ref="item__weight"
                          v-model="item.weight"
                          :rules="[rules.required, rules.weight]"
                          label=""
                          placeholder="체중을 입력해주세요"
                          type="number"
                          outlined
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        class="pl-2"
                      >
                        <p class="text-subtitle-1 font-weight-medium pt-4 pb-0">
                          Kg
                        </p>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <LabelTitle title="진단정보" />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          ref="item__medical_information"
                          v-model.trim="item.medical_information"
                          :rules="[rules.required]"
                          outlined
                          placeholder="모든 진단 정보를 입력해주세요.
예) 골절, 암, 치매 등"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <LabelTitle title="필요한 간호행위, 보조 및 환자의 특이사항" />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          ref="item__more_information"
                          v-model="item.more_information"
                          :rules="[rules.required]"
                          outlined
                          placeholder="예)석션,피딩,거동보조,식사보조 등"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <!-- 간병 정보 -->
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            md="8"
          >
            <v-container class="py-8 px-6 container-wrap">
              <v-row>
                <v-col
                  cols="12"
                  class="subtitle font-weight-bold text-h5 pb-8"
                >
                  간병 정보
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <LabelTitle title="간병이 필요한 날짜를 알려주세요" />
                  <v-container class="pa-0">
                    <v-row dense>
                      <v-col cols="6">
                        <DatePicker2
                          ref="date_picker2"
                          v-model="item.started_at"
                          placeholder="시작날짜"
                          icon="calendar_today"
                        />
                      </v-col>
                      <v-col cols="6">
                        <DatePicker2
                          v-model="item.ended_at"
                          :min-date="plusADay(item.started_at)"
                          placeholder="종료날짜"
                          icon="calendar_today"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12">
                  <LabelTitle title="지급가능 급여(하루기준)" />
                  <v-select
                    ref="item__daily_payment_selectbox"
                    v-model="daily_payment_selectbox"
                    :items="dailyPaymentItems"
                    item-text="title"
                    item-value="value"
                    placeholder="선택해주세요"
                    append-icon="expand_more"
                    outlined
                    @change="selectDailyPaymentSelectbox"
                  />
                  <v-text-field
                    v-if="isDisplayPayMentItemTextfield"
                    ref="item__daily_payment"
                    v-model.trim="item.daily_payment"
                    :rules="[rules.required, rules.dailyPayment]"
                    label="지급가능한 간병인 하루 급여를 입력해주세요"
                    placeholder="지급가능한 간병인 하루 급여를 입력해주세요"
                    hide-details
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <LabelTitle title="하루 간병 시간" />

                  <v-select
                    ref="item__care_time_info_selectbox"
                    v-model="item.care_time_info"
                    :items="careTimeInfoSelectbox"
                    placeholder="선택해주세요"
                    append-icon="expand_more"
                    outlined
                    @change="selectDailyPaymentSelectbox"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <!-- 요청사항 -->
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            md="8"
          >
            <v-container class="py-8 px-6 container-wrap">
              <v-row>
                <v-col
                  cols="12"
                  class="subtitle font-weight-bold text-h5 pb-8"
                >
                  요청사항
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <LabelTitle title="간병인 구인 시 가장 중요하다고 생각되는 항목" />
                  <v-select
                    ref="item__important_things_to_pick_career_idx"
                    v-model="item.important_things_to_pick_career_idx"
                    :items="importantThingItems"
                    item-text="reason"
                    item-value="idx"
                    placeholder="선택해주세요"
                    append-icon="expand_more"
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <LabelTitle title="원하시는 간병인의 성별" />
                  <v-container class="pa-0 pt-2">
                    <v-row dense>
                      <v-col
                        cols="4"
                      >
                        <v-btn
                          ref="item__want_carer_male"
                          block
                          outlined
                          height="50"
                          :style="`border-color: ${item.want_carer_gender === 'male' ? '#007AFF': '#9E9E9E'} !important;`"
                          :color="item.want_carer_gender === 'male' ? '#007AFF' : '#979797'"
                          @click="item.want_carer_gender = 'male'"
                        >
                          <div
                            class="btn-icon"
                            :style="`color: ${(item.want_carer_gender === 'male') ? '#007AFF' : '#2C2C2C'}`"
                            :class="item.want_carer_gender === 'male' ? 'icon-male-active': 'icon-male'"
                          >
                            남성
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="4"
                      >
                        <v-btn
                          ref="item__want_carer_female"
                          block
                          outlined
                          height="50"
                          :style="`border-color: ${item.want_carer_gender === 'female' ? '#007AFF': '#9E9E9E'} !important;`"
                          :color="item.want_carer_gender === 'female' ? '#007AFF' : '#979797'"
                          @click="item.want_carer_gender = 'female'"
                        >
                          <div
                            class="btn-icon"
                            :style="`color: ${(item.want_carer_gender === 'female') ? '#007AFF' : '#2C2C2C'}`"
                            :class="item.want_carer_gender === 'female' ? 'icon-female-active': 'icon-female'"
                          >
                            여성
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          ref="item__want_carer_any"
                          block
                          outlined
                          height="50"
                          :style="`border-color: ${item.want_carer_gender === 'any' ? '#007AFF': '#9E9E9E'} !important;`"
                          :color="item.want_carer_gender === 'any' ? '#007AFF' : '#979797'"
                          @click="item.want_carer_gender = 'any'"
                        >
                          <div
                            class="btn-icon"
                            :style="`color: ${(item.want_carer_gender === 'any') ? '#007AFF' : '#2C2C2C'}`"
                            :class="item.want_carer_gender === 'any' ? 'icon-person-active': 'icon-person'"
                          >
                            무관
                          </div>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <!-- 약관동의 -->
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            md="8"
          >
            <v-container class="py-8 px-6 container-wrap">
              <v-row>
                <v-col
                  cols="12"
                  class="subtitle font-weight-bold text-h5 pb-8"
                >
                  약관동의
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="6"
                >
                  <div class="d-flex justify-start align-center">
                    <LabelTitle
                      title="서비스 이용약관"
                      class="text-body-2"
                    />
                    <div class="mb-1 ml-4">
                      <a
                        href="/terms/terms_of_service"
                        target="_blank"
                        class="text-body-2 grey--text text--lighten-1"
                      >보기</a>
                    </div>
                  </div>
                  <v-btn
                    ref="item__agreedTermsOfService"
                    block
                    outlined
                    height="50"
                    :style="`border-color: ${agreedTermsOfService ? '#007AFF': '#9E9E9E'} !important;`"
                    :color="agreedTermsOfService ? '#007AFF' : '#2C2C2C'"
                    @click="agreedTermsOfService = !agreedTermsOfService"
                  >
                    동의합니다.
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                >
                  <div class="d-flex justify-start align-center">
                    <LabelTitle
                      title="개인정보취급방침"
                      class="text-body-2"
                    />
                    <div class="mb-1 ml-4">
                      <a
                        href="/terms/terms_of_privacy"
                        target="_blank"
                        class="text-body-2 grey--text text--lighten-1"
                      >보기</a>
                    </div>
                  </div>
                  <v-btn
                    ref="item__agreedTermsOfPrivacy"
                    block
                    outlined
                    height="50"
                    :style="`border-color: ${agreedTermsOfPrivacy ? '#007AFF': '#9E9E9E'} !important;`"
                    :color="agreedTermsOfPrivacy ? '#007AFF' : '#2C2C2C'"
                    @click="agreedTermsOfPrivacy = !agreedTermsOfPrivacy"
                  >
                    동의합니다.
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    ref="item__agreedTermsOfService"
                    block
                    outlined
                    height="50"
                    :style="`border-color: ${(agreedTermsOfService && agreedTermsOfPrivacy) ? '#007AFF': '#9E9E9E'} !important;`"
                    :color="(agreedTermsOfService && agreedTermsOfPrivacy)? '#007AFF' : '#2C2C2C'"
                    @click="agreedTermsOfService = true; agreedTermsOfPrivacy = true"
                  >
                    전체동의
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <div class="notice">
                    (주)디스피릿은 통신판매중개자로서 거래에 필요한 시스템을 운영 및 제공합니다.
                    <br>
                    환자 혹은 보호자와 간병인 사이에 발생 분쟁에 대한 책임은 환자 혹은 보호자와 간병인에게 있습니다.
                    따라서 회사는 제공하는 서비스에 대하여 어떠한 책임도 없습니다. 다만 원만한 분쟁 해결을 위해 중재자로서 최선을 다하겠습니다.
                  </div>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col
                  cols="12"
                  class="pt-8"
                >
                  <v-btn
                    block
                    color="#007AFF"
                    height="60px"
                    class="white--text"
                    @click="doRequest"
                  >
                    <div class="btn-submit">
                      간병인 요청하기
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <Snackbar />
    <SuccessDialog />
  </div>
</template>

<script>
import Dayjs from 'dayjs'
import LabelTitle from './components/LabelTitle'
import DatePicker2 from '../_elements/form/DatePicker'
// import DatePickerRangeType2 from '../_elements/form/DatePickerRangeType2'
import Snackbar from '../_elements/snackbar/BottomSnackbar'
import RequestApi from './api/request'
import { getAddressList } from '../../models/addressFinder'
import SuccessDialog from './components/SuccessDialog'

export default {
  components: {
    Snackbar,
    LabelTitle,
    DatePicker2,
    // DatePickerRangeType2,
    SuccessDialog
  },
  data() {
    return {
      valid: false,
      lazy: false,
      rules: {
        required: (value) => !!value || '꼭 입력해주세요',
        dailyPayment: (v) => (v && v.length >= 1 && v.length < 15) || '15자 이하로 입력해주세요',
        age: (v) => (v && v >= 1 && v <= 100) || '나이는 1세 ~ 100세 사이로 입력해주세요',
        weight: (v) => (v && v >= 1 && v <= 200) || '체중은 1 ~ 200 사이로 입력해주세요'
      },
      daily_payment_selectbox: '',
      isDisplayPayMentItemTextfield: false,
      dailyPaymentItems: [
        { idx: 1, title: '가격협의 (가격을 잘 모르시거나 애매하신 경우)', value: '가격협의' },
        { idx: 2, title: '기타', value: '기타' },
        { idx: 3, title: '1만원', value: '1만원' },
        { idx: 4, title: '2만원', value: '2만원' },
        { idx: 5, title: '3만원', value: '3만원' },
        { idx: 6, title: '4만원', value: '4만원' },
        { idx: 7, title: '5만원', value: '5만원' },
        { idx: 8, title: '6만원', value: '6만원' },
        { idx: 9, title: '7만원', value: '7만원' },
        { idx: 10, title: '8만원', value: '8만원' },
        { idx: 11, title: '9만원', value: '9만원' },
        { idx: 12, title: '10만원', value: '10만원' },
        { idx: 13, title: '11만원', value: '11만원' },
        { idx: 14, title: '12만원', value: '12만원' },
        { idx: 15, title: '13만원', value: '13만원' },
        { idx: 16, title: '14만원', value: '14만원' },
        { idx: 17, title: '15만원', value: '15만원' },
        { idx: 18, title: '16만원', value: '16만원' },
        { idx: 19, title: '17만원', value: '17만원' },
        { idx: 20, title: '18만원', value: '18만원' },
        { idx: 21, title: '19만원', value: '19만원' },
        { idx: 22, title: '20만원', value: '20만원' }
      ],
      careTimeInfoSelectbox: [
        '24시간', '주간 12시간', '야간 12시간', '시간협의'
      ],
      importantThingItems: [],
      selectedRegion: {
        idx: null
      },
      selectedCity: {
        idx: null
      },
      regions: [],
      cities: [],
      selectedPlace: '',
      placeDetailLabel: '',
      selectedGender: '',
      selectedRequestGender: '',
      agreedTermsOfService: false,
      agreedTermsOfPrivacy: false,
      item: {
        // 신청자 정보
        name: '', // 신청인 이름
        phone: '', // 신청인 핸드폰 번호

        // 환자 장소 정보
        location_idx: '', // 환자 장소
        location_detail_idx: '', // 환자 세부 지역
        location_type: '', // 환자 장소 (자택, 병원)
        location_type_detail_1: '', // 세부 정보 자택의 경우 자택 주소, 병원의 경우 병원 이름
        location_type_detail_2: '', // 병원 호실

        // 환자 정보
        age: '', // 환자 나이
        gender: '', // 성별 (male, female)
        weight: '', // 무게 (kg)
        medical_information: '', // 진단정보 - 병명, 정확한 증상 등
        more_information: '', // 간병인이 알아야 할 특이사항 - 의료 정보 외에 추가적으로 알아야 하는 내용

        // 간병 요청 정보
        started_at: '', // 간병 시작일
        ended_at: '', // 간병 종료일
        daily_payment: '', // 희망급여 - 지급 가능한 일급
        care_time_info: '24시간', // 하루 간병 시간

        // 요청사항
        want_carer_gender: '', // 희망하는 간병인 성별 (male, female)
        important_things_to_pick_career_idx: '' // 가장 중요하다고 생각되는 항목
      }
    }
  },
  computed: {
    pickerStartDateFrom() {
      // .add(1, 'days')
      return Dayjs(new Date())
        .format('YYYY-MM-DD')
    },
    pickerStartDateFromLimit() {
      return Dayjs(new Date())
        .add(3, 'years')
        .format('YYYY-MM-DD')
    },
    pickerEndDateFrom() {
      if (this.periodFrom === null) {
        return null
      }
      return Dayjs(this.periodFrom, 'YYYY-MM-DD')
        .format('YYYY-MM-DD')
        // .add(1, 'days')
    },
    pickerEndDateFromLimit() {
      if (this.periodFrom === null) {
        return null
      }
      return Dayjs(this.periodFrom, 'YYYY-MM-DD')
        .add(3, 'years')
        .format('YYYY-MM-DD')
    }
  },
  watch: {
    'item.started_at': {
      handler(v) {
        if (v && this.item.ended_at) {
          const startedAt = new Date(v).getTime()
          const endedAt = new Date(this.item.ended_at).getTime()
          if (startedAt >= endedAt) {
            this.item.ended_at = ''
          }
        }
      }
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    setDummyData() {
      this.item = {
        age: "40",
        care_time_info: "24시간",
        daily_payment: "8만",
        ended_at: "2021-07-30",
        gender: "male",
        important_things_to_pick_career_idx: 6,
        location_detail_idx: 197,
        location_idx: 14,
        location_type: "hospital",
        location_type_detail_1: "큰병원",
        location_type_detail_2: "NS병동 401호",
        medical_information: "정신착란, 정신분열",
        more_information: "가끔 헛소리를 합니다",
        name: "최영재",
        phone: "01054389816",
        started_at: "2021-07-01",
        want_carer_gender: "female",
        weight: "76"
      }
    },
    async init() {
      await this.getRegions()
      await this.getImportThingItems()
      // this.setDummyData()
    },
    async getRegions() {
      const items = await getAddressList()
      console.log(items)
      // this.regions = items
      this.regions = items.filter((item) => item.area !== '전체')
      console.log(this.regions)
    },
    async getImportThingItems() {
      this.importantThingItems = await RequestApi.getImportThingItems()
    },
    async onChangeRegion() {
      const items = await getAddressList(this.selectedRegion.idx)
      this.cities = items.filter((item) => item.area !== '전체')
      this.item.location_idx = this.selectedRegion.idx
    },
    async onChangeCity() {
      this.item.location_detail_idx = this.selectedCity.idx
      // this.item.location_type_detail_1 = [this.selectedRegion.short_name, this.selectedCity.short_name].join(' ')
    },
    async selectPlace(place) {
      this.selectedPlace = place
      if (place === 'home') {
        this.item.location_type = 'home'
        // this.placeDetailLabel = '자택 주소를 입력해주세요. (상세주소는 비공개입니다)'
        this.placeDetailLabel = '자택주소 (상세주소는 안적으셔도 됩니다)'
        // this.placeDetailLabel = '자택주소를 입력해주세요.(상세주소는 비공개)'
      } else if (place === 'hospital') {
        this.item.location_type = 'hospital'
        // this.placeDetailLabel = '병동과 호실을 입력해주세요. 미정이거나 모르시면 안적으셔도 됩니다'
        this.placeDetailLabel = '병동, 호실 (모르시면 안적으셔도 됩니다)'
      }
    },
    selectDailyPaymentSelectbox() {
      if (this.daily_payment_selectbox === '기타') {
        this.isDisplayPayMentItemTextfield = true
        this.item.daily_payment = ''
        this.$nextTick(() => {
          this.$refs.item__daily_payment.focus()
        })
      } else {
        this.isDisplayPayMentItemTextfield = false
        this.item.daily_payment = this.daily_payment_selectbox
      }
    },
    showSnackbar(msg) {
      this.$bus.$emit('successMessage', msg)
    },
    async doRequest() {
      // if (this.$refs.form.validate() === false)
      if (this.item.phone === '' || this.item.phone.length !== 11) {
        this.showSnackbar('연락처를 입력해 주세요')
        this.$refs.item__phone.focus()
      } else if (this.item.location_idx === '') {
        this.showSnackbar('시/도를 선택해주세요')
        this.$refs.item__location_idx.focus()
      } else if (this.item.location_detail_idx === '') {
        this.showSnackbar('시/군/구를 선택해주세요')
        this.$refs.item__location_detail_idx.focus()
      } else if (this.item.location_type === '') {
        this.showSnackbar('계시는 장소를 선택해주세요')
        this.$refs.item__location_type.$el.focus()
      } else if (this.item.location_type === 'hospital' && this.item.location_type_detail_1 === '') {
        this.showSnackbar('병원 이름을 입력해주세요')
        this.$refs.item__location_type_detail_1.focus()
      } else if (this.item.location_type_detail_2 === '' && this.selectedPlace === 'home') {
        this.showSnackbar('상세 주소를 입력해주세요')
        this.$refs.item__location_type_detail_2.focus()
      } else if (this.item.name === '') {
        this.showSnackbar('환자 성함을 입력해주세요')
        this.$refs.item__name.focus()
      } else if (this.item.age === '') {
        this.showSnackbar('나이를 입력해주세요')
        this.$refs.item__age.focus()
      } else if (this.rules.age(this.item.age) !== true) {
        this.showSnackbar(this.rules.age(this.item.age))
        this.$refs.item__age.focus()
      } else if (this.item.gender === '') {
        this.showSnackbar('성별을 선택해주세요')
        this.$refs.item__gender_male.$el.focus()
      } else if (this.item.weight === '') {
        this.showSnackbar('체중을 입력해주세요')
        this.$refs.item__weight.focus()
      } else if (this.rules.weight(this.item.weight) !== true) {
        this.showSnackbar(this.rules.weight(this.item.weight))
        this.$refs.item__weight.focus()
      } else if (this.item.medical_information === '') {
        this.showSnackbar('모든 진단 정보를 입력해주세요')
        this.$refs.item__medical_information.focus()
      } else if (this.item.more_information === '') {
        this.showSnackbar('특이사항에 대해서 입력해주세요')
        this.$refs.item__more_information.focus()
      } else if (this.item.started_at === '' || this.item.ended_at === '') {
        this.showSnackbar('간병이 필요한 날짜를 선택해주세요')
        this.$refs.date_picker2.$refs.item__date_picker.focus()
      } else if (this.item.daily_payment === '') {
        this.showSnackbar('희망급여를 입력해주세요')
        this.$refs.item__daily_payment.focus()
      } else if (this.item.care_time_info === '') {
        this.showSnackbar('하루 간병 시간을 입력해주세요')
        this.$refs.item__care_time_info_selectbox.focus()
      } else if (this.item.important_things_to_pick_career_idx === '') {
        this.showSnackbar('간병인 구인 시 중요하다고 생각되는 항목을 선택해주세요')
        this.$refs.item__important_things_to_pick_career_idx.focus()
      } else if (this.item.want_carer_gender === '') {
        this.showSnackbar('원하시는 간병인의 성별을 선택해주세요')
        this.$refs.item__want_carer_any.$el.focus()
      } else if (this.agreedTermsOfService === false) {
        this.showSnackbar('모든 약관에 동의하셔야 간병신청 서비스를 이용하실 수 있습니다')
        this.$refs.item__agreedTermsOfService.$el.focus()
      } else if (this.agreedTermsOfPrivacy === false) {
        this.showSnackbar('모든 약관에 동의하셔야 간병신청 서비스를 이용하실 수 있습니다')
        this.$refs.item__agreedTermsOfPrivacy.$el.focus()
      } else {
        const result = await RequestApi.requestFindCaregiver(this.item)
        if (result === true) {
          this.$bus.$emit('showSuccessDialog')
        } else {
          this.showSnackbar('등록되지 못했습니다')
        }
      }
    },
    plusADay(v) {
      return Dayjs(v).add(1, 'day').format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .item__location_type_detail_2 {
   div.v-text-field__slot > input {
     font-size: 0.9rem;
   }
}

.section-wrap {
  background-color: #F9F9F9;
  // background-color: #ccc;
  padding-bottom: 120px;
}
.subtitle {
  color: #025BBC;
}
.container-wrap {
  background: #FFF;
}
.btn-icon {
  padding-left: 24px;
}
.icon-home {
  background: url('./assets/icon_home.png') left center no-repeat;
  background-size: 20px;
}
.icon-home-active {
  background: url('./assets/icon_home_active.png') left center no-repeat;
  background-size: 20px;
}
.icon-hospital {
  background: url('./assets/icon_hospital.png') left center no-repeat;
  background-size: 20px;
}
.icon-hospital-active {
  background: url('./assets/icon_hospital_active.png') left center no-repeat;
  background-size: 20px;
}
.icon-male {
  background: url('./assets/icon_male.png') left center no-repeat;
  background-size: 20px;
}
.icon-male-active {
  background: url('./assets/icon_male_active.png') left center no-repeat;
  background-size: 20px;
}
.icon-female {
  background: url('./assets/icon_female.png') left center no-repeat;
  background-size: 20px;
}
.icon-female-active {
  background: url('./assets/icon_female_active.png') left center no-repeat;
  background-size: 20px;
}
.icon-person {
  background: url('./assets/icon_person.png') left center no-repeat;
  background-size: 20px;
}
.icon-person-active {
  background: url('./assets/icon_person_active.png') left center no-repeat;
  background-size: 20px;
}
.btn-submit {
  background: url('./assets/icon_person_wb.png') left center no-repeat;
  background-size: 20px;
  padding-left: 28px;
}
.notice {
  background: #F9F9F9;
  border-radius: 5px;
  padding: 24px;
  font-size: 15px;
}
</style>
