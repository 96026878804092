<template>
  <div>
    <Banner />
    <RequestForm />
  </div>
</template>

<script>
import Banner from './Banner'
import RequestForm from './RequestForm'

export default {
  components: {
    Banner,
    RequestForm
  }
}
</script>

<style lang="scss" scoped>
</style>
